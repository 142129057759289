@import './vars.scss';

@import url('https://fonts.googleapis.com/css2?family=Helvetica:wght@300;400;500;700&display=swap');

/**
 * DOM styles
 */
html,
body {
  height: 100%;
  font-family: 'Helvetica', sans-serif !important;
  font-size: 15px;
}

body,
p {
  margin: 0;
}

button {
  min-height: 42px;
}

.w-100 {
  width: 100%;
}

.has-linebreaks {
  white-space: pre-line;
  word-break: break-word;
}

.has-text-primary {
  color: $primary;
}

.has-background-primary {
  background-color: $primary;
}

.has-font-weight-bold {
  font-weight: 700;
}

h1 {
  font-size: 2rem;
  font-weight: 600;
}

h3 {
  font-size: 1.5rem;
  font-weight: 600;
}

h5 {
  font-size: 1.125rem;
  font-weight: 400;
}

.anchor {
  color: $anchor;
  cursor: pointer;
  text-decoration: underline;

  &:hover {
    color: $anchor-hover;
  }
}

.error {
  color: $danger;
}

.logo {
  max-width: 250px;
  width: 100%;
}

.heading {
  color: $primary;
  font-weight: 600;
  text-decoration: none;
}

.hint {
  color: $gray-3;
  display: inline-block;
  font-style: italic;
  font-size: 0.8rem;
}

.chip {
  background-color: $gray-1;
  border: 1px solid $gray-2;
  border-radius: 3px;
  font-size: 0.85rem;
  padding: 5px 10px;
  text-align: center;
}

/**
 * Angular Material
 */
mat-card {
  height: fit-content;
}

mat-list-item {
  font-size: unset !important; // Otherwise, the font-size will be different than the root
}

mat-radio-group {
  display: block;

  mat-radio-button {
    display: block;
    width: 100%;
  }
}

th.mat-header-cell,
td.mat-cell {
  vertical-align: middle !important;
}
