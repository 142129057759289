$primary: #396a6a;
$primary-disabled: #809b9b;
$primary-hover: #246060;

$danger: #d98d4f;
$danger-disabled: #b17360;
$danger-hover: #a7633e;

$white: #ffffff;
$gray-1: #f5f4ef;
$gray-2: #c4c3be;
$gray-3: #707070;
$black: #313132;

$anchor: #2589bd;
$anchor-hover: #147eb4;

$blue: #258cbe;

$table-hover: $gray-1;

$family-sans-serif: 'Helvetica';

$border-radius: 3px;
$mat-form-field-width-default: 300px;
